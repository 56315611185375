import React, { FC, ReactNode, useState, useEffect } from "react";
import ButtonCircle from "shared/Button/ButtonCircle";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Input from "shared/Input/Input";

export interface SectionHeroProps {
  className?: string;
  rightImg: string;
  heading: ReactNode;
  subHeading: string;
  btnText: string;
}

const SectionHero: FC<SectionHeroProps> = ({
  className = "",
  rightImg,
  heading,
  subHeading,
  btnText,
}) => {


  const [acquistato, setAcquistato] = useState(0);
  const [speso, setSpeso] = useState(0);
  const [codice, setCodice] = useState('');
    const [orders, setOrders] = useState([]);
   const [saldo, setSaldo] = useState(null);

  const getBracciale = () => {
    fetch(`https://booking.jooba.it/saldo_card?codice=${codice}`, {
      method: "GET",
    })
      .then((response) => response.json())
      .then(async (response) => {
       
        // setAcquistato(response.acquistato);
        setSaldo(response.saldo);
         setOrders(response.orders);
      })
      .catch((error) => console.log(error));
  };


  return (
    <div className={`nc-SectionHero relative ${className}`} data-nc-id="SectionHero">
      <div className="flex flex-col lg:flex-row space-y-10 lg:space-y-0 lg:space-x-10 items-center relative text-center lg:text-left">
        <div className="w-screen max-w-full xl:max-w-lg space-y-5 lg:space-y-7">
          <h2 className="text-3xl !leading-tight font-semibold text-neutral-900 md:text-4xl xl:text-5xl dark:text-neutral-100">{heading}</h2>
          <span className="block text-base xl:text-lg text-neutral-6000 dark:text-neutral-400">{subHeading}</span>
          <div className="relative max-w-sm">
            <Input
              required
              value={codice}
              aria-required
              onInput={(e) => setCodice((e.target as HTMLInputElement).value)}
              placeholder="Codice bracciale"
              type="text"
            />
            <ButtonCircle
              type="submit"
              onClick={() => {
                getBracciale();
              }}
              className="absolute transform top-1/2 -translate-y-1/2 right-1"
            >
              <i className="las la-arrow-right text-xl"></i>
            </ButtonCircle>
          </div>
        </div>

        <div className="flex-grow">
          {/* <img className="w-full" src={rightImg} alt="" /> */}
          {saldo && (
            <div className="w-screen max-w-full text-left item-start xl:max-w-lg space-y-5 lg:space-y-7">
              {/* <span className="block text-base xl:text-lg text-neutral-6000 dark:text-neutral-400">
              🪙 Tipologia di card: {cad}
            </span>
            <span className="block text-base xl:text-lg text-neutral-6000 dark:text-neutral-400">
              💳 Totale speso: {speso*-1}
            </span> */}
              <span className="text-3xl !leading-tight font-semibold text-neutral-900 md:text-4xl xl:text-5xl dark:text-neutral-100">
                💶 Saldo : {saldo}
              </span>
              <table className="table-auto w-full border-collapse border border-gray-300">
                <thead>
                  <tr className="bg-gray-200">
                    <th className="px-4 py-2 border border-gray-300">Prodotto</th>
                    <th className="px-4 py-2 border border-gray-300">Qta</th>
                    <th className="px-4 py-2 border border-gray-300">Prezzo</th>
                    <th className="px-4 py-2 border border-gray-300">Ora</th>
                  </tr>
                </thead>
                <tbody>
                  {orders.map((el, index) => (
                    <tr key={index} className="hover:bg-gray-100">
                      <td className="px-4 py-2 border border-gray-300 text-center">{el.prodotto}</td>
                      <td className="px-4 py-2 border border-gray-300 text-center">{el.qta}</td>
                      <td className="px-4 py-2 border border-gray-300 text-center">{el.prezzo}</td>
                      <td className="px-4 py-2 border border-gray-300 text-center">{new Date(el.created_at).toLocaleTimeString()}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SectionHero;
